import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Header from '../containers/layout/Header'
import Footer from '../containers/layout/Footer'

const Title = styled.div`
  flex: 15vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #009ee0;
  font-size: 2.5vw;
  font-weight: 100;  
`

const Body = styled.div`
  margin: 0vw;
  flex: 30vw 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NotFount = () => {
  const { t } = useTranslation()
  return (<>
    <Header title={t('home-title')} />
    <Title>{t('page-not-found')}</Title>
    <Body />
    <Footer />
  </>)

}

export default NotFount