import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import DigitalCenterImage from '../../../assets/images/digital-center.png'
import OrdersTrackingImage from '../../../assets/images/orders-tracking.png';
import OrdersDataImage from '../../../assets/images/orders-data.png';
import SmartengoSolutionsImage from '../../../assets/images/smartengo-solutions.png'
import StoreImage from '../../../assets/images/store.png'


const Wrapper = styled.div`
  margin: 0 7vw;
  flex: 22.65vw 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
`

const ShortcutCardWrapper = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;  
  cursor: pointer;  
`

const ShortcutCardImage = styled.div`
  flex: 6.25vw 0;
`

const ShortcutCardTitle = styled.div`
  flex: 1 0;
  color: #404243;
  font-size: 1.2vw;
  font-weight: 100;
`

const ShortcutCardFooter = styled.div`
  flex: 0.2vw 0;
  background-color: #009ee0;
  margin: 5px;
  width: 3.65vw;
`

const Shortcuts = () => {
  const { t } = useTranslation()

  const jumpTo = (anchor) => () => {
    document.getElementById(anchor).scrollIntoView()
 }
   
  const ShortcutCard = ({image, label, onClick}) => {
    return <ShortcutCardWrapper onClick={onClick}>
      <ShortcutCardImage>
        <img src={image}  alt={label} style={{width: ' 5.25vw'}} />
      </ShortcutCardImage>
      <ShortcutCardTitle>
        {label}
      </ShortcutCardTitle>
      <ShortcutCardFooter />
    </ShortcutCardWrapper> 
  }  

  return <Wrapper>
      <ShortcutCard image={DigitalCenterImage} label={t('product-expertise')} onClick={jumpTo('product-expertise')} />
      <ShortcutCard image={OrdersTrackingImage} label={t('orders-tracking')} onClick={jumpTo('orders-tracking')} />
      <ShortcutCard image={OrdersDataImage} label={t('orders-data')} onClick={jumpTo('orders-data')} />
      <ShortcutCard image={SmartengoSolutionsImage} label={t('smartengo-solutions')} onClick={jumpTo('smartengo-solutions')} />
      <ShortcutCard image={StoreImage} label={t('store')} onClick={jumpTo('store')} />
  </Wrapper>
}

export default Shortcuts