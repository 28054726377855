const apps = {
    productExpertiseLinks: [
      {id: 'product-catalog', title: 'product-catalog', icon: 'product-catalog', arrowType: '1', text: 'product-catalog-description', linkUrl: 'https://solutions.vallourec.com', linkType: 'go-to-app', disabled: false },
      {id: 'digital-expertise-center', title: 'digital-expertise-center', icon: 'online-assistance', arrowType: '1', text: 'digital-expertise-center-description', linkUrl: 'https://expertise.vallourec.com', linkType: 'go-to-app', disabled: false },
      // {id: 'online-assistance', title: 'online-assistance', icon: 'online-assistance', arrowType: '1', text: 'online-assistance-description', linkUrl: '#', linkType: 'go-to-app', disabled: false },
      // {id: 'training', title: 'training', icon: 'training', arrowType: '1', text: 'training-description', linkUrl: '#', linkType: 'go-to-app', disabled: false },
    ],
    ordersTrackingLinks: [
      {appId: 'VALLOUREC_ONLINE', id: 'vallourec-online', title: 'vallourec-online', icon: 'vallourec-online', arrowType: '2', text: 'vallourec-online-description', linkUrl: 'https://vallourec.outsystemsenterprise.com/VallourecOnline_UI_Commons/Login.aspx', linkType: 'go-to-app', disabled: false },
      {appId: 'ELISA', id: 'elisa', title: 'elisa', icon: 'elisa', arrowType: '2', text: 'elisa-description', linkUrl: 'https://elisa.vallourec.com/', linkType: 'go-to-app', disabled: false },
    ],
    ordersDataLinks: [
      {appId: 'DATABOOK', id: 'digital-databook', title: 'digital-databook', icon: 'digital-databook', arrowType: '2', text: 'digital-databook-description', linkUrl: 'https://databook.vallourec-smart.com', linkType: 'go-to-app', disabled: false },
      {appId: 'BACKBONE', id: 'backbone', title: 'backbone', icon: 'digital-inbound', arrowType: '2', text: 'backbone-description', linkUrl: 'https://int.smartengo-backbone.aws.smartdev.vallourec.com', linkType: 'go-to-app', disabled: false },
    ],
    smartengoSolutionsLinks: [
      {appId: 'TRACEABILITY', id: 'traceability', title: 'traceability', icon: 'traceability', arrowType: '4', text: 'traceability-description', linkUrl: 'https://solutions.vallourec.com/en/Services/Smartengo-Traceability', linkType: 'discover+demo', disabled: false },
      {appId: 'INVENTORY', id: 'inventory', title: 'inventory', icon: 'inventory', arrowType: '4', text: 'inventory-description', linkUrl: 'https://solutions.vallourec.com/en/Services/Smartengo-Inventory', linkDemo: 'https://inventory.aws.smart.vallourec.com', linkType: 'discover+demo', disabled: false },
      {appId: 'RUNNING_EXPERT', id: 'running-expert', title: 'running-expert', icon: 'running-expert', arrowType: '4', text: 'running-expert-description', linkUrl: 'https://solutions.vallourec.com/en/Services/Smartengo-Running-Expert', linkType: 'discover+demo', disabled: false },
      {appId: 'BEST_FIT', id: 'best-fit', title: 'best-fit', icon: 'best-fit', arrowType: '4', text: 'best-fit-description', linkUrl: 'https://solutions.vallourec.com/en/Services/Smartengo-Best-Fit', linkType: 'discover+demo', disabled: false },
    ],
    eCommerceLinks: [
      {id: 'e-commerce', title: 'e-commerce', icon: 'e-commerce', arrowType: '2', text: 'e-commerce-description', linkUrl: 'https://smartengo.vallourec.com' }
    ]
}

export default apps   