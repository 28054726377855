import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import VallourecLogo from '../../assets/images/logo-white.png'
import ArrowDown from '../../assets/images/arrow-down-3.png'
import Facebook from '../../assets/images/facebook.png'
import Twitter from '../../assets/images/twitter.png'
import Youtbube   from '../../assets/images/youtube.png'
import Linkedin from '../../assets/images/linkedin.png'

const Wrapper = styled.div`
  color: #fff;
  font-weight: 400; 
  font-size: 1vw;

  background-color: #000000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;    
  flex: 8vw 0;
  
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;  
`
const FooterLogo = styled.div`
  flex: 12vw 0;
  display: flex;
  align-items: center;
  margin: 0 2vw;  
`
const FooterAccess = styled.div`
  flex: 18vw 0;
  display: flex;
  align-items: center;
  margin: 0 2vw;  
`
const FooterPolicy = styled.div`
  flex: 10vw 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 2vw;
  font-size: 0.8vw;
  border-left: 1px solid #fff;
  padding-left: 1.5vw;
`

const FooterSocialContainer = styled.div`
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 2vw; 
  color: #009ee0 
`

const Footer = () => {

  const { t } = useTranslation()
  const history = useHistory()

  const onLinkClick = (action) => () => {
    history.push(action)
  }

  return <Wrapper>
    <FooterLogo>
      <img src={VallourecLogo} style={{height: '2.5vw'}} alt="logo" />
    </FooterLogo>
    <FooterAccess>
      {t('our-websites')}:
      <span style={{color: '#009ee0', marginLeft: '2vw'}}>{t('customer-portal')}</span>
      <img src={ArrowDown} style={{height: '1vw', marginLeft: '0.2vw'}} alt="Select" /> 
    </FooterAccess>
    <FooterPolicy>
      <div style={{margin: '0.2vw 0', cursor: 'pointer'}} onClick={onLinkClick('/legal-notice')}>{t('legal-notice.label')}</div>
      <div style={{margin: '0.2vw 0', cursor: 'pointer'}} onClick={onLinkClick('/privacy-policy')}>{t('privacy-policy.label')}</div>
      <div style={{margin: '0.2vw 0', cursor: 'pointer'}} onClick={onLinkClick('/terms-user')}>{t('terms-of-use.label')}</div>
    </FooterPolicy>
    <FooterSocialContainer>
      <span style={{marginRight: '0.5vw'}}>{t('English')}</span>
      <img src={ArrowDown} style={{height: '1vw', marginLeft: '0.2vw'}} alt="Select" /> 
      <a rel="noreferrer" target="_blank" href="https://www.facebook.com/Vallourec/">
        <img src={Facebook} style={{height: '1.2vw', marginLeft: '2vw'}} alt="Select" /> 
      </a>
      <a rel="noreferrer" target="_blank" href="https://twitter.com/vallourec/">
        <img src={Twitter} style={{height: '1.2vw', marginLeft: '1vw'}} alt="Select" /> 
      </a>
      <a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UCgIl0xm1ANwajVkfCFvZr0w">
        <img src={Youtbube} style={{height: '1.2vw', marginLeft: '1vw'}} alt="Select" /> 
      </a>
      <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/vallourec/">
        <img src={Linkedin} style={{height: '1.2vw', marginLeft: '1vw'}} alt="Select" /> 
      </a>
    </FooterSocialContainer>
  </Wrapper>
}

export default Footer