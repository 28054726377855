export const responseHandle = (response) => {
    const responseData = response.data ? response.data.body !== undefined ? response.data.body : response.data : response;
    return responseData;
};

export const errorHandle = (error) => {
    let message = JSON.stringify(error); 
    if (error.response) {    
        if (error.response.data) {
            try {
                const data = JSON.parse(error.response.data);
                if (data.message) {
                    message = data.message;
                } else
                if (data.errorMessage) {
                    message = data.errorMessage;
                }                
            } catch (tryError) {
                message = error.response.data.message ? error.response.data.message : 
                            error.response.data.errorMessage ? error.response.data.errorMessage : 
                                error.response.data; 
            }
        } else {
            try {
                const data = JSON.parse(error.response);
                if (data.message) {
                    message = data.message;
                } else
                if (data.errorMessage) {
                    message = data.errorMessage;
                }                
            } catch (tryError) {
                message = error.response.message ? error.response.message : 
                    error.response.errorMessage ? error.response.errorMessage : 
                        error.response; 
            }            
        }
    } else  {
        try {
            const data = JSON.parse(error);
            if (data.message) {
                message = data.message;
            } else
            if (data.errorMessage) {
                message = data.errorMessage;
            }                
        } catch (tryError) {
            message = error.message ? error.message : 
                error.errorMessage ? error.errorMessage : 
                    message; 
        }                
    }

    if (message.indexOf('Invalid Security Token') !== -1) {
        message = 'Invalid Security Token';
    } else if (message.indexOf('Unauthorized') !== -1) {
        message = 'Unauthorized';
    } else if (message.indexOf('Token is expired') !== -1) {
        message = 'Token is expired';
    }                     

    return message;
};
