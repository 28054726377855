import React from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Header from '../containers/layout/Header'
import Footer from '../containers/layout/Footer'

const Subtitle = styled.div`
  margin-top: 2vw;
  color: #009ee0;
  font-size: 2vw;
  font-weight: 100;  
`

const Text = styled.div`
  margin: 1vw;
  padding: 0;
  font-size: 1.3vw;
  font-weight: 100;  
`

const Paragraph = styled.div`
  margin: 0;
  padding: 0.2vw;
`

const Body = styled.div`
  margin: 2vw 0;
  flex: 15vw 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const LegalNotice = () => {

  const { t } = useTranslation()

  return (<>
    <Header title={t('legal-notice.title')} />
    <Body>
      <Subtitle>{t('legal-notice.subtitle1')}</Subtitle>
      <Text>{t('legal-notice.text1')}</Text>
      <Subtitle>{t('legal-notice.subtitle2')}</Subtitle>
      <Text>{t('legal-notice.text2').split('\n').map(text => <Paragraph>{text}</Paragraph>)}</Text>
      <Subtitle>{t('legal-notice.subtitle3')}</Subtitle>
      <Text>{t('legal-notice.text3').split('\n').map(text => <Paragraph>{text}</Paragraph>)}</Text>
    </Body>    
    <Footer />
  </>)

}

export default LegalNotice
