import React, {useContext, useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import HeaderBackground from '../../assets/images/header.png'
import HeaderBackgroundSigned from '../../assets/images/header-signed.png'
import VallourecLogo from '../../assets/images/logo-white.svg'
import UserImage from '../../assets/images/user-account.png'
import Logout from '../../assets/images/logout.png'

import { UserContext } from '../../stores/UserStore'
// import { toast } from 'react-toastify'
// import { LoadingOverlay } from '../../components/common'

import '../../assets/css/sign-in.css'
import '../../assets/css/menu-dropdown.css'


const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;    
  /* opacity: 0.78; */
  flex: 30vw 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;  
`


const unsignedBackgroundImage  = {
  backgroundImage: `linear-gradient(to top, #009ee069, #172983), url(${HeaderBackground})`
}

const signedBackgroundImage  = {
  backgroundImage: `linear-gradient(to top,#009ee03d,#172983),url(${HeaderBackgroundSigned})`
}


const HeaderTop = styled.div`
  margin: 1.56vw;
  flex: 3.125vw 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`
const HeaderLogo = styled.div`
  flex: 15vw 0;
  cursor: pointer;
`
const HeaderMenu = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  color: #fff;
  font-size: 1vw;
  font-weight: 400;  
  padding-bottom: 0.7vw;
`

const HeaderMenuItem = styled.div`
  text-align: center;
  cursor: pointer;
  flex: 1 0;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: flex-start;
`

const HeaderBody = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
const HeaderBodyTitle = styled.div`
  // margin: 6vw 0;
  margin: 2.5vw 0;
  color: #fff;
  font-size: 3.9vw;
  font-weight: 100;
`

const HeaderBodySignin = styled.button`
  font-size: 1.5vw;
  text-transform: uppercase;
  color: #009ee0;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px, rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px;
  border-radius: 4px;
  padding: 1.2vw;
  margin: 0.4vw;
  text-align: center;
  border: none;  
  width: 18vw;
  cursor: pointer;
`

const HeaderBodySubtitle = styled.div`
  margin: 0;
  color: #fff;
  font-size: 3.5vw;
  font-weight: 100;
`

const HeaderUserWrapper = styled.div`
  flex: 10vw 0;
  color: #fff;
  font-size: 0.8vw;
  font-weight: 700; 
  display: flex;
  align-items: center;
  justify-content: flex-end;  
`
const HeaderUser = styled.div`
  flex: auto 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0.2vw;
`

const Header = ({isHome, title}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { signOut, authState, currentUser, currentPicture } = useContext(UserContext)

  const jumpTo = (anchor) => () => {
    if (isHome) {
      document.getElementById(anchor).scrollIntoView()
    } else {
      history.push("/")
      setTimeout(() => {
        document.getElementById(anchor).scrollIntoView()      
      }, 500);
    }
  }

  const onSignInClick = () => {
    window.location.replace(`${process.env.REACT_APP_LOGIN_URL}${`?app=CLP`}`)
  }

  const onLogoClick = () => {
    history.push("/")
  }

  // const onContactClick = () => {
  //   history.push("/contact")
  // }

  const onSignOutClick = () => {
    signOut();
  }

  return <Wrapper style={authState === 'SIGNED_IN' ? signedBackgroundImage : unsignedBackgroundImage}>
    <HeaderTop>
      <HeaderLogo onClick={onLogoClick}>
        <img src={VallourecLogo} style={{height: '4vw'}} alt="logo" />
      </HeaderLogo>
      <HeaderMenu>
        <HeaderMenuItem onClick={jumpTo('product-expertise')}>
          {authState === 'SIGNED_IN' && <span>{t('my')}</span>} {t('product-expertise')}
        </HeaderMenuItem>
        {/* <HeaderMenuItem onClick={jumpTo('orders-tracking')}>
          {t('orders-tracking')}
        </HeaderMenuItem>
        <HeaderMenuItem onClick={jumpTo('orders-data')}>
          {t('orders-data')}
        </HeaderMenuItem> */}
        <HeaderMenuItem className="dropdown">
          <span className="dropdown-title">{authState === 'SIGNED_IN' && <span>{t('my')}</span>} {t('orders')}</span>
          <div className="dropdown-content orders">
            <div onClick={jumpTo('orders-tracking')}>{t('orders-tracking')}</div>
            <div onClick={jumpTo('orders-data')}>{t('orders-data')}</div>
          </div>
        </HeaderMenuItem>
        <HeaderMenuItem onClick={jumpTo('smartengo-solutions')}>
          {authState === 'SIGNED_IN' && <span>{t('my')}</span>} {t('smartengo-solutions')}
        </HeaderMenuItem>
        <HeaderMenuItem onClick={jumpTo('store')}>
          {authState === 'SIGNED_IN' && <span>{t('my')}</span>} {t('store')}
        </HeaderMenuItem>
        {/* <HeaderMenuItem onClick={onContactClick}>
          {t('contact')}
        </HeaderMenuItem> */}
        <HeaderMenuItem style={{ flex: "3vw 0", fontSize: "0.8vw", fontWeight: "700", textAlign: 'right' }}> 
          &nbsp;
        </HeaderMenuItem>
        {/* <HeaderMenuItem style={{ flex: "8vw 0", fontSize: "0.8vw", fontWeight: "700", textAlign: 'right' }} onClick={onSignInClick}> 
          {t('register')} / {t('signin')}
        </HeaderMenuItem> */}
      </HeaderMenu>
      {authState === 'SIGNED_IN' && <HeaderUserWrapper>
      {currentPicture ? <img src={currentPicture} style={{height: '3vw', borderRadius: '50px', marginRight: '10px'}} alt="user" /> : <img src={UserImage} style={{height: '1.5vw', marginRight: '10px'}} alt="user" />}
        <HeaderUser>
            {currentUser && currentUser.firstName.substring(0,1)}. {currentUser && currentUser.lastName.toUpperCase()}
            <img src={Logout}  onClick={onSignOutClick} alt="Sign Out" title="Sign Out" style={{height: '1vw', marginLeft: '0.5vw', cursor: 'pointer'}} /> 
        </HeaderUser>
      </HeaderUserWrapper>}
    </HeaderTop>
    <HeaderBody>
      {
        authState === 'SIGNED_IN' ? <>
          <HeaderBodyTitle>{t('welcome')} {currentUser && currentUser.firstName}</HeaderBodyTitle>
          <HeaderBodySubtitle>{t('signed-title')}</HeaderBodySubtitle>
        </> :
        authState === 'WAITING_FOR_ACCESS' ? <>
          <HeaderBodyTitle>{t('home-title')}</HeaderBodyTitle>
        </> :
        <>
          <HeaderBodyTitle>{t('home-title')}</HeaderBodyTitle>
          <HeaderBodySignin onClick={onSignInClick}>{t('signin')}</HeaderBodySignin>
        </>
      }

      {/* {title && <HeaderBodyTitle>{title}</HeaderBodyTitle> }
      <HeaderBodySignin onClick={onSignInClick}>{t('signin')}</HeaderBodySignin> */}
    </HeaderBody>

  </Wrapper>
}

export default Header