import styled, { css, keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import SpinnerImage from '../../assets/icons/spinner.svg'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`


/**
 * Spinner component for activity indication
 */
const Spinner = styled.div`
  display: inline-block;
  animation: ${rotate} 1s linear infinite;
  ${({ theme, color }) => css`
      background-color: ${theme.colors[color] || theme.colors.primary};
      mask-image: url(${SpinnerImage});
    `
  }
  background-repeat: no-repeat;
  background-size: cover;
  mask-size: cover;
  width: ${({ theme, size }) => theme.fontSizes[size] || size || '40px'};
  height: ${({ theme, size }) => theme.fontSizes[size] || size || '40px'};
`

Spinner.propTypes = {
  /**
   * A color key defined in the theme
   */
  color: PropTypes.string,
  /**
   * Define spinner size with CSS compliant value or font size theme key. Examples: medium, big, 8px, 1rem, 40%, 6vw
   */
  size: PropTypes.string,
}

export default Spinner