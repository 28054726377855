import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ArrowRight3 from '../../../assets/images/arrow-right-3.png'
import ECommerce from '../../../assets/images/e-commerce.png'
import MockupSmartengo from '../../../assets/images/mockup-smartengo.png'

const Wrapper = styled.div`
  margin: 0 0 10vw;
  flex: 22.65vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
`
const StoreCardWrapper = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`
const StoreCardImage = styled.div`
  flex: 25vw 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const StoreCardContainer = styled.div`
  margin: 0 1.5vw;
  padding: 3.5vw;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;   
`
const StoreCardActionTitle = styled.div`
  margin: 0;
  color: #009ee0;
  font-size: 1.8vw;
  font-weight: 100;
`
const StoreCardAction = styled.div`
  margin: 1.5vw;
  flex: 3vw 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch; 
`
const StoreCardActionImage = styled.div`
  flex: 5vw 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`
const StoreCardActionBody = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;    
`
const StoreCardActionHeader = styled.div`
  flex: 6vw 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;   
`
const StoreCardActionContent = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;   
`
// const StoreCardActionSubtitle = styled.div`
//   font-size: 1.5vw;
//   font-weight: 100;  
// `

const StoreCardActionText = styled.div`
  flex: 1 0;
  font-size: 1.2vw;
  font-weight: 100;  
`
const StoreCardActionArrow = styled.a`
  flex: 3vw 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;  
  cursor: pointer;
`
const StoreCardFooter = styled.div`
  flex: 0.2vw 0;
  background-color: #009ee0;
  margin: 5px;
  width: 10vw;
`

const StoreCard = () => {
  const { t } = useTranslation()

  return <Wrapper id="store">
    <StoreCardWrapper>
      <StoreCardImage>
        <img src={MockupSmartengo} style={{width: '25vw'}} alt="Mockup Smartengo" />
      </StoreCardImage>
      <StoreCardContainer>
        <StoreCardAction>
          <StoreCardActionImage>            
            <img src={ECommerce} style={{width: '4vw'}} alt="Store" />
          </StoreCardActionImage>
          <StoreCardActionBody>
            <StoreCardActionHeader>
              <StoreCardActionTitle>{t('store')}</StoreCardActionTitle>
              {/* <StoreCardActionSubtitle>{t('smartengo-e-commerce')}</StoreCardActionSubtitle> */}
            </StoreCardActionHeader>
            <StoreCardActionContent>
              <StoreCardActionText>{t('smartengo-e-commerce-description')}</StoreCardActionText>
              <StoreCardActionArrow href="https://smartengo.vallourec.com" target="_blank">            
                <img src={ArrowRight3} style={{width: '2vw'}} alt="Go" />
              </StoreCardActionArrow>
            </StoreCardActionContent>
          </StoreCardActionBody>
        </StoreCardAction> 
      </StoreCardContainer>
    </StoreCardWrapper>
    <StoreCardFooter />
   </Wrapper>
}

export default StoreCard