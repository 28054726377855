import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { responseHandle, errorHandle } from '../utils/callback-handle';
import LoadingOverlay from '../components/common/LoadingOverlay';
import { toast } from 'react-toastify';

const initialState = {
  user: null,
  settings: []
};

export const UserContext = React.createContext(initialState);

export const UserProvider = ({ children }) => {
   const TOKEN_COOKIE = '_ssoToken';

    const [currentUser, setCurrentUser] = useState(null);
    const [currentPicture, setCurrentPicture] = useState(null);
    const [settings, setSettings] = useState(null);
    const [authState, setAuthState] = useState('LOGIN');
    const [isLoading, setLoading] = useState(true);

    const authApi = axios.create({
      baseURL: process.env.REACT_APP_AUTH_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    });       

    useEffect(() => {
      const hydrate = () => new Promise((resolve, reject) => {
        //Load user in session. If no user or session expired try to refresh token;
        const query = new URLSearchParams(window.location.search);
        const verifyToken = query.get('token');
        
        const idToken = Cookies.get(TOKEN_COOKIE);
        if (idToken) {
          if (verifyToken) {
            setAuthState('RELOAD');
            resolve();   
          } else {
            getUserDetails(idToken).then(() => {
              getUserPicture(idToken).then();
              setAuthState('SIGNED_IN');
              resolve();
            }).catch(err => {
              reject(catchAuthState(err));
            });
          }   
        } else {
          if (verifyToken) {
            getUserVerify(verifyToken).then((idToken) => {
                const expires = new Date(new Date().getTime() + 60 * 60 * 1000) //expires in 60 minutes 
                Cookies.set(TOKEN_COOKIE, idToken, { expires: expires, domain: process.env.REACT_APP_DOMAIN })    
                setAuthState('RELOAD');
                resolve();   
            }).catch(err => {
              reject(catchAuthState(err));
            });   
          } else {
            setAuthState('LOGIN');
            reject('No user authentivated');
          }
        }
      });
  
      hydrate().catch(err => {
        toast.error(err.message);
      }).finally(() => {
        setLoading(false);
      })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps  

    const getUserDetails = (token) => new Promise((resolve, reject) => {  
      //Load user details from server using access token;
      const headerToken = { 'Authorization': token, 'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID })) }
      authApi.post('/user/detail', {}, { headers: headerToken }).then((response) => {
        //If success save user detail data in state;
        const data = responseHandle(response);
        setCurrentUser(data.user);
        setSettings(data.rootSettings);
        resolve();
      }).catch(err => {
        console.error('currentAuthenticatedUser', err);
        reject(err);
      });
    });

    const getUserVerify = (token) => new Promise((resolve, reject) => {  
      const headerToken = { 'Authorization': token, 'VLR-Authorization': btoa(JSON.stringify({ "id": process.env.REACT_APP_ID })) }
      authApi.post('/user/verify', {}, { headers: headerToken }).then((response) => {
        const data = responseHandle(response);
        resolve(data);
      }).catch(err => {
        console.error('verifyUser', err);
        reject(err);
      });
    });
    
    const catchAuthState = (err) => {
      if (err.response) {
        if (err.response.status === 402) {
          setAuthState('WAITING_FOR_ACCESS');
        } else
        if (err.response.status === 403) {
          setAuthState('ACCESS_DENIED');
        } else
        if (err.response.status === 401) {
          setAuthState('REFRESH_TOKEN');
        }          
      }          
      return errorHandle(err);
    }

    const signOut = async () => {
      try {
        Cookies.remove(TOKEN_COOKIE, { path: '/', domain: process.env.REACT_APP_DOMAIN });
        setCurrentUser(null);
        setAuthState(null);
      }
      catch (e) {
        console.debug(e)
      }
    }
  
    const refreshSession = async () => {
      try {
        Cookies.remove(TOKEN_COOKIE, { path: '/', domain: process.env.REACT_APP_DOMAIN });
        setCurrentUser(null);
        setAuthState('REFRESH_TOKEN');
      }
      catch (e) {
        console.debug(e)
      }
    }

    const getUserPicture = (token) => new Promise((resolve, reject) => {  
      //Load user details from server using access token;
      const headerToken = { 'Authorization': token }
      authApi.post('/user/picture', {}, { headers: headerToken }).then((response) => {
        //If success save user detail data in state;
        const data = responseHandle(response);
        setCurrentPicture(data);
        resolve();
      }).catch(err => {
        console.error('userPicture', err);
        reject(err);
      });
    });
    
     const renderStore = isLoading ?
    <LoadingOverlay open={true} />
    :
    <UserContext.Provider value={{
        currentUser,
        currentPicture,
        settings,
        authState,
        refreshSession,
        signOut
        }}>
        {children}
      </UserContext.Provider>;

  return renderStore;

}    