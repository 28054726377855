import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ArrowRight1 from '../../../assets/images/gradient-arrow-right-1.png'
import ArrowRight2 from '../../../assets/images/gradient-arrow-right-2.png'
import ArrowRight3 from '../../../assets/images/gradient-arrow-right-3.png'
import ArrowRight4 from '../../../assets/images/gradient-arrow-right-3.png'

import OnlineAssistance from '../../../assets/images/online-assistance.png'
import BestFit from '../../../assets/images/best-fit.png'
import DigitalDatabook from '../../../assets/images/digital-databook.png'
import DigitalInbound from '../../../assets/images/digital-inbound.png'
import ECommerce from '../../../assets/images/e-commerce.png'
import Elisa from '../../../assets/images/elisa.png'
import Inventory from '../../../assets/images/inventory.png'
import ProductCatalog from '../../../assets/images/product-catalog.png'
import RunningExpert from '../../../assets/images/running-expert.png'
import Traceability from '../../../assets/images/traceability.png'
import Training from '../../../assets/images/training.png'
import VallourecOnline from '../../../assets/images/vallourec-online.png'
import UserAccount from '../../../assets/images/user-account.png'

import OnlineAssistanceDisabled from '../../../assets/images/online-assistance-disabled.png'
import BestFitDisabled from '../../../assets/images/best-fit-disabled.png'
import DigitalDatabookDisabled from '../../../assets/images/digital-databook-disabled.png'
import DigitalInboundDisabled from '../../../assets/images/digital-inbound-disabled.png'
import ECommerceDisabled from '../../../assets/images/e-commerce-disabled.png'
import ElisaDisabled from '../../../assets/images/elisa-disabled.png'
import InventoryDisabled from '../../../assets/images/inventory-disabled.png'
import ProductCatalogDisabled from '../../../assets/images/product-catalog-disabled.png'
import RunningExpertDisabled from '../../../assets/images/running-expert-disabled.png'
import TraceabilityDisabled from '../../../assets/images/traceability-disabled.png'
import TrainingDisabled from '../../../assets/images/training-disabled.png'
import VallourecOnlineDisabled from '../../../assets/images/vallourec-online-disabled.png'


export const icons = {
  'online-assistance': OnlineAssistance,
  'best-fit': BestFit,
  'digital-databook': DigitalDatabook,
  'digital-inbound': DigitalInbound,
  'e-commerce': ECommerce,
  'elisa': Elisa,
  'inventory': Inventory,
  'product-catalog': ProductCatalog,
  'running-expert': RunningExpert,
  'traceability': Traceability,
  'training': Training,
  'vallourec-online': VallourecOnline,
  'online-assistance-disabled': OnlineAssistanceDisabled,
  'best-fit-disabled': BestFitDisabled,
  'digital-databook-disabled': DigitalDatabookDisabled,
  'digital-inbound-disabled': DigitalInboundDisabled,
  'e-commerce-disabled': ECommerceDisabled,
  'elisa-disabled': ElisaDisabled,
  'inventory-disabled': InventoryDisabled,
  'product-catalog-disabled': ProductCatalogDisabled,
  'running-expert-disabled': RunningExpertDisabled,
  'traceability-disabled': TraceabilityDisabled,
  'training-disabled': TrainingDisabled,
  'vallourec-online-disabled': VallourecOnlineDisabled
}

export const arrows = {
  '1': ArrowRight1,
  '2': ArrowRight2,
  '3': ArrowRight3,
  '4': ArrowRight4
}

const Wrapper = styled.div`
  margin: 5vw 1.5vw;
  flex: 18vw 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch; 
`

const AppsWrapper = styled.div`
  margin: 0;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch; 
`
const ShortcutWrapper = styled.div`
  margin: 0;
  flex: 1 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start; 
`
const ShortcutTitle = styled.div`
  margin: 1vw;
  flex: 1vw 0;
  color: #009ee0;
  font-size: 1.8vw;
  font-weight: 100;
`

const ShortcutCardWrapper = styled.div`
  flex: 10vw 0;
  margin: 0 1.5vw 0 0;
  padding: 1vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;  
  background-color: #f3f3f3;
  width: 15vw;
  height: 12vw;
  min-width: 15vw;
  min-height: 12vw;
  flex-wrap: wrap;

  border-radius: 1vw;
  -moz-border-radius: 1vw;
  -webkit-border-radius: 1vw;

  -webkit-box-shadow: 1vw 1vw 1vw 0px rgba(230,230,230,0.85);
  -moz-box-shadow: 1vw 1vw 1vw 0px rgba(230,230,230,0.85);
  box-shadow: 1vw 1vw 1vw 0px rgba(230,230,230,0.85);  
`

const ShortcutAppCardImage = styled.div`
  flex: 4vw 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;    

`

const ShortcutAppCardFooter = styled.div`
  flex: 0.2vw 0;
  font-size: 0.6vw;
  font-weight: 400;
  text-align: center;  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;  
`

const ShortcutAppCard = styled.div`
  margin: 0;
  margin-left: 0.2vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;  
  width: 6vw;
  height: 5vw;
  min-width: 6vw;
  min-height: 5vw;
  cursor: pointer;  
`

const AccountWrapper = styled.div`
  margin: 0;
  flex: 14vw 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch; 
`

const AccountImage = styled.div`
  margin: 0 3vw;
  flex: 6.6vw 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  border-radius: 4vw;
  -moz-border-radius: 4vw;
  -webkit-border-radius: 4vw;
  z-index: 999999;
  border: 0.7vw solid #ffffff;
  background: linear-gradient(to top,#63caf5,#031b8da1);
`
const AccountBody = styled.div`
  margin: 0;
  margin-top: -4vw;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch; 
  border-radius: 1vw;
  -moz-border-radius: 1vw;
  -webkit-border-radius: 1vw;
  background-color: #f3f3f3;  
`

const AccountBodyText = styled.div`
  flex: 1 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4vw;  
  font-size: 1vw;  
`

const AccountBodyView = styled.button`
  flex: 0.8vw 0;
  font-size: 0.8vw;
  font-weight: 100;
  color: #ffffff;
  border-radius: 0.8vw;
  padding: 0.5vw;
  margin: 1.5vw;
  text-align: center;
  border: none;  
  width: 9vw;
  cursor: pointer;
  background: linear-gradient(to top,#63caf5,#031b8d73);
`


const ShortcutsSigned = ({apps}) => {
  const { t } = useTranslation()

  const jumpTo = (anchor) => () => {
    document.getElementById(anchor).scrollIntoView()
 }
   
  const ShortcutCard = ({links}) => {
      return <ShortcutCardWrapper >
        {links && links.map((link) => {
          return <ShortcutAppCard key={link.id} onClick={jumpTo(link.id)}>
             <ShortcutAppCardImage>{link.disabled ? <img src={icons[link.icon+'-disabled']} style={{marginLeft: '1.1vw', width: '4vw'}} alt={t(link.title)} /> :  <img src={icons[link.icon]} style={{marginLeft: '1.1vw', width: '4vw'}} alt={t(link.title)} /> } </ShortcutAppCardImage>
             <ShortcutAppCardFooter>
               <div>
                 {t(link.title)}
               </div>
               <img src={arrows[link.arrowType]} style={{marginLeft: '0.5vw', width: '0.4vw'}} alt="Go" />
              </ShortcutAppCardFooter>
        </ShortcutAppCard>})}
      </ShortcutCardWrapper>
  }  

  return <Wrapper>
      <AppsWrapper>
        <ShortcutTitle>My Apps</ShortcutTitle>
        <ShortcutWrapper>
          <ShortcutCard links={apps.productExpertiseLinks} />
          <ShortcutCard links={apps.smartengoSolutionsLinks} />
          <ShortcutCard links={apps.eCommerceLinks} />
        </ShortcutWrapper>
      </AppsWrapper>
      <AccountWrapper>
        <AccountImage>
        <img src={UserAccount} style={{margin: '0', width: '4vw'}} alt="Account" />
        </AccountImage>
        <AccountBody>
          <AccountBodyText>{t('my')} {t('account')}</AccountBodyText>
          <AccountBodyView>{t('view')}</AccountBodyView>
        </AccountBody>
      </AccountWrapper>
  </Wrapper>
}

export default ShortcutsSigned