/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { UserContext } from '../stores/UserStore'

import Header from '../containers/layout/Header'
import Shortcuts from '../containers/home/shortcuts/Shortcuts'
import LinkCard from '../containers/home/cards/LinkCard'
import StoreCard from '../containers/home/cards/StoreCard'
import Footer from '../containers/layout/Footer'

import ShortcutsSigned from '../containers/signed/shortcuts/Shortcuts'
import LinkCardSigned from '../containers/signed/cards/LinkCard'
import StoreCardSigned from '../containers/signed/cards/StoreCard'

import { toast } from 'react-toastify'

import apps from '../utils/data'

const Home = () => {
  const { t } = useTranslation()
  const { authState, user } = useContext(UserContext)

  useEffect(() => {
    if (user && user.applications && user.applications.length > 0) {
      apps.ordersTrackingLinks.forEach((link) => {
        const app = user.applications.find(x => x.id === link.appId)
        link.disabled = !app
      });
      apps.ordersDataLinks.forEach((link) => {
        const app = user.applications.find(x => x.id === link.appId)
        link.disabled = !app
      });
      apps.smartengoSolutionsLinks.forEach((link) => {
        const app = user.applications.find(x => x.id === link.appId)
        link.disabled = !app
      });
    }
  }, [user])

  useEffect(() => {
    if (authState === 'RELOAD') {
      window.location.replace(window.location.origin);
    } else
    if (authState === 'REFRESH_TOKEN') {
      window.location.replace(process.env.REACT_APP_LOGIN_URL+'/refresh?app='+process.env.REACT_APP_ID)
    } else
    if (authState === 'ACCESS_DENIED') {
      window.location.replace(process.env.REACT_APP_LOGIN_URL+'/request?app='+process.env.REACT_APP_ID)
    } else
    if (authState === 'WAITING_FOR_ACCESS') {
      toast.success('Waiting for Access! You have an request to access the Customer Access application!')
      setTimeout(()=>{
        window.location.replace(process.env.REACT_APP_LOGIN_URL+'/logout?app='+process.env.REACT_APP_ID)
      }, 4000)
    }
  }, [authState])
 
  const render = useMemo(() => <>
      <Header isHome={true} />
      { authState === 'SIGNED_IN' ? <> 
        <ShortcutsSigned apps={apps} />
        <LinkCardSigned id="product-expertise" label={t('product-expertise')} links={apps.productExpertiseLinks} />
        <LinkCardSigned id="orders-tracking" label={t('orders-tracking')} links={apps.ordersTrackingLinks} />
        <LinkCardSigned id="orders-data" label={t('orders-data')} links={apps.ordersDataLinks} />
        <LinkCardSigned id="smartengo-solutions" label={t('smartengo-solutions')} links={apps.smartengoSolutionsLinks} />
        <StoreCardSigned />
      </> : <>
        <Shortcuts />
        <LinkCard id="product-expertise" label={t('product-expertise')} links={apps.productExpertiseLinks} />
        <LinkCard id="orders-tracking" label={t('orders-tracking')} links={apps.ordersTrackingLinks} />
        <LinkCard id="orders-data" label={t('orders-data')} links={apps.ordersDataLinks} />
        <LinkCard id="smartengo-solutions" label={t('smartengo-solutions')} links={apps.smartengoSolutionsLinks} />
        <StoreCard />
      </> }
      <Footer />
    </>, [authState])

  return (render)
}

export default Home