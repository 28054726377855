import React from 'react'
import Router from './Router'
import './i18n'
import theme from './utils/theme'
import { toast, Zoom } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import { AppProvider } from './stores/AppStore'
import { UserProvider } from './stores/UserStore'

toast.configure({
  autoClose: 4000,
  draggable: false,
  transition: Zoom,
  hideProgressBar: false,
  closeButton: false
})

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <UserProvider>
          <Router />
        </UserProvider>
      </AppProvider>
    </ThemeProvider>
  )
}

export default App
