import React from 'react'
const initialState = {
  name: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION
}

export const AppContext = React.createContext(initialState)

export const AppProvider = ({ children }) => {

  return <AppContext.Provider value={{
    ...initialState,
  }}>
    {children}
  </AppContext.Provider>
}