import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import Home from './pages/Home'
import NotFound from './pages/NotFound'
import LegalNotice from './pages/LegalNotice'

const Background = styled.div`
  margin: 0px 0px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;  

  @media screen and (min-width: 1280px) {
    margin: 0px 150px;
  }  
`

const Router = () => {

  return ( 
    <Background>
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/legal-notice' component={LegalNotice} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Background>
  )
}

export default Router