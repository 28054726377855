import React from 'react'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import ArrowRight1 from '../../../assets/images/arrow-right-1.png'
import ArrowRight2 from '../../../assets/images/arrow-right-2.png'
import ArrowRight3 from '../../../assets/images/arrow-right-3.png'
import ArrowRight4 from '../../../assets/images/arrow-right-4.png'

import OnlineAssistance from '../../../assets/images/online-assistance.png'
import BestFit from '../../../assets/images/best-fit.png'
import DigitalDatabook from '../../../assets/images/digital-databook.png'
import DigitalInbound from '../../../assets/images/digital-inbound.png'
import ECommerce from '../../../assets/images/e-commerce.png'
import Elisa from '../../../assets/images/elisa.png'
import Inventory from '../../../assets/images/inventory.png'
import ProductCatalog from '../../../assets/images/product-catalog.png'
import RunningExpert from '../../../assets/images/running-expert.png'
import Traceability from '../../../assets/images/traceability.png'
import Training from '../../../assets/images/training.png'
import VallourecOnline from '../../../assets/images/vallourec-online.png'


export const icons = {
  'online-assistance': OnlineAssistance,
  'best-fit': BestFit,
  'digital-databook': DigitalDatabook,
  'digital-inbound': DigitalInbound,
  'e-commerce': ECommerce,
  'elisa': Elisa,
  'inventory': Inventory,
  'product-catalog': ProductCatalog,
  'running-expert': RunningExpert,
  'traceability': Traceability,
  'training': Training,
  'vallourec-online': VallourecOnline
}

export const arrows = {
  '1': ArrowRight1,
  '2': ArrowRight2,
  '3': ArrowRight3,
  '4': ArrowRight4
}

const Wrapper = styled.div`
  margin: 0 0 2vw;
  flex: 22.65vw 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
`

const LinkCardWrapper = styled.div`
  margin: 0 1.5vw;
  padding: 3.5vw;
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;  

  background-color: #f3f3f3;

  border-radius: 1vw;
  -moz-border-radius: 1vw;
  -webkit-border-radius: 1vw;

  -webkit-box-shadow: 2.5vw 1.5vw 1vw 0px rgba(230,230,230,0.85);
  -moz-box-shadow: 2.5vw 1.5vw 1vw 0px rgba(230,230,230,0.85);
  box-shadow: 2.5vw 1.5vw 1vw 0px rgba(230,230,230,0.85);   
`

const LinkCardTitle = styled.div`
  margin: 0 1.5vw;
  flex: 1vw 0;
  color: #009ee0;
  font-size: 1.8vw;
  font-weight: 100;
`

const LinkCardAction = styled.div`
  margin: 1.5vw;
  flex: 3vw 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch; 
`

const LinkCardActionImage = styled.div`
  flex: 8vw 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const LinkCardActionBody = styled.div`
  flex: 1 0;
`

const LinkCardActionBodyTitle = styled.div`
  flex: 1 0;
  font-size: 1.5vw;
  font-weight: 100;  
  display: flex;
`

const LinkCardActionBodyText = styled.div`
  flex: 1 0;
  font-size: 1.2vw;
  font-weight: 100;  
`

// const LinkCardActionArrow = styled.div`
//   flex: 3vw 0;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;  
//   cursor: pointer;
// `

const LinkCardActionBodyLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #009ee0;
  font-weight: 300;  
  cursor: pointer;
  margin-left: 2vw;
`

const LinkCardTitleBar = styled.div`
  flex: 0.2vw 0;
  background-color: #009ee0;
  margin: 3px 1.5vw 4vw;
  width: 7vw;
`

const LinkCard = ({id, label, links}) => {
  const { t } = useTranslation()

  const onLickClick = (url) => () => {
    window.open(url, '_blank')
  }
  

  return <Wrapper id={id}>
    <LinkCardWrapper>
      <LinkCardTitle>{label}</LinkCardTitle>
      <LinkCardTitleBar />
      {links.map((link) => {
        return <LinkCardAction key={link.title}>
          <LinkCardActionImage>            
            <img src={icons[link.icon]} style={{width: '4vw'}} alt={t(link.title)} />
          </LinkCardActionImage>
          <LinkCardActionBody>
            <LinkCardActionBodyTitle>
              {t(link.title)}
              <LinkCardActionBodyLink onClick={onLickClick(link.linkUrl)}>
              {link.linkType === 'go-to-app' ? t('go-to-app') : t('discover')}
              <img src={arrows[link.arrowType]} style={{marginLeft: '1vw', width: '1.2vw'}} alt="Go" />
              </LinkCardActionBodyLink>
            </LinkCardActionBodyTitle>
            <LinkCardActionBodyText>{t(link.text)}</LinkCardActionBodyText>
          </LinkCardActionBody>
          {/* {link.linkType === 'go-to-app' && <LinkCardActionArrow onClick={onLickClick(link.linkUrl)}>            
            <img src={arrows[link.arrowType]} style={{width: '2vw'}} alt="Go" />
          </LinkCardActionArrow>} */}
        </LinkCardAction> 
      })}
    </LinkCardWrapper>
   </Wrapper>
}

export default LinkCard